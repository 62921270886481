<template>
	<page>
		<module title="保单详情">
			<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
			<c-detail>
				<c-detail-item label="项目名称" full>
					{{detailInfo.project_name}}
				</c-detail-item>
				<c-detail-item label="项目所属行业">
					{{detailInfo.sshy}}
				</c-detail-item>
				<c-detail-item label="金融机构">
					{{detailInfo.bxgs_name}}
				</c-detail-item>
				<c-detail-item label="企业名称">
					{{detailInfo.zbqy_name}}
				</c-detail-item>
				<c-detail-item label="保函类型">
					{{detailInfo.bx_type_name}}
				</c-detail-item>
				<c-detail-item label="企业法人">
					{{detailInfo.zbqy_fr_name}}
				</c-detail-item>
				<c-detail-item label="工期">
					{{detailInfo.sggq}}天
				</c-detail-item>
				<c-detail-item label="授权人">
					{{detailInfo.sqr_name}}
				</c-detail-item>
				<c-detail-item label="保单额度">
					￥{{detailInfo.bd_money}}
				</c-detail-item>
				<c-detail-item label="企业联系电话">
					{{detailInfo.zbqy_phone}}
				</c-detail-item>
				<c-detail-item label="保费金额">
					￥{{detailInfo.bf_money}}
				</c-detail-item>
				<c-detail-item label="企业所属行业">
					{{detailInfo.zbqy_sshy}}
				</c-detail-item>
				<c-detail-item label="正式保单">
					<c-file v-if="detailInfo.state == 4" :src="detailInfo.bx_bd_file_path"></c-file>
					<span v-else style="color: #E63633;">未制单</span>
				</c-detail-item>
				<c-detail-item label="企业资质">
					{{detailInfo.zbqy_qyzz}}
				</c-detail-item>
				<c-detail-item label="保函状态">
					<span v-if="detailInfo.state" :style="{'color': insuranceState[detailInfo.state].color}">{{insuranceState[detailInfo.state].name}}</span>
				</c-detail-item>
				<c-detail-item label="业主单位">
					{{detailInfo.yzdw_name}}
				</c-detail-item>
				<c-detail-item label="被保险人">
					{{detailInfo.bbxr_name}}
				</c-detail-item>
				<c-detail-item label="业主单位地址">
					{{detailInfo.yzdw_address}}
				</c-detail-item>
				<c-detail-item label="被保险人组织代码">
					{{detailInfo.bbxr_jgdm}}
				</c-detail-item>
				<c-detail-item label="申请时间" full>
					{{detailInfo.create_date}}
				</c-detail-item>
			</c-detail>
			
			<c-detail title="金融机构收款信息">
				<c-detail-item label="收款账号" full>
					{{insuranceInfo.bank_code}}
				</c-detail-item>
				<c-detail-item label="户名">
					{{insuranceInfo.account_name}}
				</c-detail-item>
				<c-detail-item label="开户行">
					{{insuranceInfo.bank_name}}
				</c-detail-item>
			</c-detail>
			
			<c-detail v-if="detailInfo.fp_fk_path" title="支票凭证及开票信息">
				<c-detail-item label="付款凭证">
					<c-media :src="detailInfo.fp_fk_path"></c-media>
				</c-detail-item>
				<c-detail-item label="发票类型">
					<span v-if="invoiceTypeName[detailInfo.fp_type]">{{invoiceTypeName[detailInfo.fp_type].name}}</span>
				</c-detail-item>
				<div v-if="detailInfo.fp_type != 3">
					<c-detail-item label="发票抬头">
						{{detailInfo.fp_title}}
					</c-detail-item>
					<c-detail-item label="银行账户">
						{{detailInfo.fp_account}}
					</c-detail-item>
					<c-detail-item label="纳税识别号">
						{{detailInfo.fp_nsrsbh}}
					</c-detail-item>
					<c-detail-item label="开户银行">
						{{detailInfo.fp_bank}}
					</c-detail-item>
					<c-detail-item label="收票联系人">
						{{detailInfo.fp_lxr}}
					</c-detail-item>
					<c-detail-item label="接收发票邮箱">
						{{detailInfo.fp_email}}
					</c-detail-item>
					<c-detail-item label="收票地址">
						{{detailInfo.fp_address}}
					</c-detail-item>
					<c-detail-item label="收票人电话">
						{{detailInfo.fp_lxdh}}
					</c-detail-item>
				</div>
			</c-detail>
			
			<c-detail title="申报材料">
				<c-detail-item label="营业执照">
					<div class="flex-start-end">
						<c-media :src="detailInfo.yyzz_path"></c-media>
						<a style="margin-left: 10px; font-size: 14px; color: #1492ff;" @click="downloadFile(detailInfo.yyzz_path)">下载营业执照</a>
					</div>
				</c-detail-item>
				<c-detail-item label="法人身份证头像面">
					<c-media :src="detailInfo.frsfztx_path"></c-media>
				</c-detail-item>
				<c-detail-item label="资质证书">
					<c-media :src="detailInfo.zzzs_path"></c-media>
				</c-detail-item>
				<c-detail-item label="法人身份证国徽面">
					<c-media :src="detailInfo.frsfzgh_path"></c-media>
				</c-detail-item>
				<c-detail-item label="招标文件">
					<c-file :src="detailInfo.files_path"></c-file>
				</c-detail-item>
				<c-detail-item label="中标通知书">
					<c-file :src="detailInfo.zb_path"></c-file>
				</c-detail-item>
				<c-detail-item label="反担保承诺书">
					<c-file :src="detailInfo.fdb_path"></c-file>
				</c-detail-item>
				<c-detail-item label="投保单">
					<c-file :src="detailInfo.tbd_path"></c-file>
				</c-detail-item>
				<c-detail-item label="业务申请书">
					<c-file :src="detailInfo.ywsqs_path"></c-file>
				</c-detail-item>
				<c-detail-item label="征信授权书">
					<c-file :src="detailInfo.zxsqs_path"></c-file>
				</c-detail-item>
				<c-detail-item v-if="detailInfo.bx_type_id == 2" label="竣工验收报告">
					<c-file :src="detailInfo.jgysbg_path"></c-file>
				</c-detail-item>
				<c-detail-item label="施工合同附件" :full="detailInfo.bx_type_id != 2">
					<c-file :src="detailInfo.sght_path"></c-file>
				</c-detail-item>
				<c-detail-item label="财务报表" full>
					<c-file :src="detailInfo.cwbb_path"></c-file>
				</c-detail-item>
			</c-detail>
			
			<c-detail title="审批流程">
				<c-detail-item label="审批记录" full>
					<timeline :data="timeline"></timeline>
				</c-detail-item>
			</c-detail>
		</module>
	</page>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import {mapGetters} from 'vuex'
	
	export default {
		components: {
			flowNode,
			timeline
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '提交申请(待付款)'
				}, {
					name: '(金融公司)审批'
				}, {
					name: '办理完结'
				}],
				detailInfo: {},
				insuranceInfo: {},
				timeline: '',
				orderInfo: {}
			}
		},
		
		computed: {
			...mapGetters(['insuranceState', 'invoiceTypeName']),
			
			currentNode() {
				return this.detailInfo.state == 5 ? 0 : this.detailInfo.state - 1;
			},
			
			rejectNode() {
				return this.detailInfo.state == 5 ? 2 : 0;
			}
		},
		
		watch: {
			id() {
				this.updateDetail();
			}
		},
		
		mounted() {
			this.updateDetail();
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/bx/detail',
					data: {
						bx_id: this.id
					},
					success: data => {
						this.detailInfo = data.bxInfo;
						this.insuranceInfo = data.bxgsInfo;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>